import React from 'react';
import PropTypes from 'prop-types';
import translate from '../../../commonComponents/translate';

function FeatureNotAvailable(props) {
  const { t, title } = props;
  return (
    <div>
      <div className="unavailable-feature-container">
        <div className="icon-container" />
        <div className="text-container">
          <h1>{t('settings.configuration.feature.notAvailable', title)}</h1>
          <h5>{t('settings.configuration.feature.notAvailable.hint')}</h5>
        </div>
      </div>
    </div>
  );
}

FeatureNotAvailable.propTypes = {
  t: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default translate(FeatureNotAvailable);
