import PropTypes from 'prop-types';
import BrandingProp from './brandingProp';
import TermsProp from './termsProp';

const propType = {
  branding: PropTypes.shape(BrandingProp.propType),
  terms: PropTypes.shape(TermsProp.propType),
  settings: PropTypes.shape({
    distributorPageAvailableToAllTypes: PropTypes.bool,
  }),
};

export const PAYMENT_PROVIDER_TYPE = {
  STRIPE: 'stripe',
  NONE: 'none',
  BANK_TRANSFER_MANUAL: 'bank_transfer_manual',
};

export const PAYMENT_PROVIDER = {
  NONE: 'none',
  BANK_TRANSFER_MANUAL: 'bank_transfer_manual',
  STRIPE: 'stripe',
  STRIPE_INVOICES: 'stripe_invoices',
};

export default { propType };
