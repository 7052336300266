import React from 'react';
import PropTypes from 'prop-types';
import translate from '../../../../commonComponents/translate';
import CountryProp from '../../../../commonComponents/forms/Address/CountryProp';
import LanguageInput from '../../../../commonComponents/forms/inputs/LanguageInput';

function LanguageForm(props) {
  const {
    t,
    id,
    organizationLanguages,
    onChange,
    defaultLanguage,
    commissionType,
  } = props;
  return organizationLanguages.map(language => {
    const isDefaultLanguage =
      defaultLanguage?.toLowerCase() === language?.code?.toLowerCase();
    const label = !isDefaultLanguage
      ? language.name
      : `${t('name')} (${language.name})`;
    const value = commissionType?.translations?.[language.code];
    return (
      <LanguageInput
        key={language?.code}
        id={`${id}-${language?.code}`}
        label={label}
        language={language}
        onChange={onChange}
        value={value}
      />
    );
  });
}

LanguageForm.propTypes = {
  t: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  defaultLanguage: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  organizationLanguages: PropTypes.arrayOf(
    PropTypes.shape(CountryProp.propType),
  ).isRequired,
  commissionType: PropTypes.shape({
    name: PropTypes.string,
    translations: PropTypes.shape(),
  }).isRequired,
};

function TabCommissionTypes(props) {
  const {
    t,
    commissionTypes,
    activeTypesOnOrganization,
    onChange,
    organizationLanguages,
    defaultLanguage,
  } = props;
  return (
    <div id="tabCommissionTypes">
      <input
        type="hidden"
        id="commissionTypes"
        name="commissionTypes"
        value={JSON.stringify(commissionTypes)}
      />
      <section>
        <h4>{t('settings.configurations.commissionTypes')}</h4>
        <div className="commissionTypesContainer">
          {activeTypesOnOrganization.map(type => {
            const commissionType = commissionTypes?.find(c => c?.type === type);
            return (
              <div className="commission-type-wrapper" key={type}>
                <h3 className="commissionType-header">
                  {t(`commissions.userCommissions.${type}`)}
                </h3>
                <LanguageForm
                  t={t}
                  id={type}
                  onChange={onChange}
                  organizationLanguages={organizationLanguages}
                  defaultLanguage={defaultLanguage}
                  commissionType={commissionType}
                />
              </div>
            );
          })}
        </div>
      </section>
    </div>
  );
}

TabCommissionTypes.propTypes = {
  t: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultLanguage: PropTypes.string.isRequired,
  organizationLanguages: PropTypes.arrayOf(
    PropTypes.shape(CountryProp.propType),
  ).isRequired,
  activeTypesOnOrganization: PropTypes.arrayOf(PropTypes.string).isRequired,
  commissionTypes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      translations: PropTypes.shape(),
    }),
  ).isRequired,
};
TabCommissionTypes.defaultProps = {};

export default translate(TabCommissionTypes);
