import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import i18n from '../../services/i18n';
import TabCommissionTypes from '../../sections/settings/configurations/general/TabCommissionTypes';
import OrganizationProp from '../../commonComponents/propTypes/organizationProp';
import FeatureNotAvailable from '../../sections/settings/configurations/FeatureNotAvailable';
import TenantProp from '../../commonComponents/propTypes/tenantProp';
import CountryProp from '../../commonComponents/forms/Address/CountryProp';

const TabCommissionTypesWebHandler = forwardRef((props, ref) => {
  const { propagateChanges, ...childProps } = props;
  const {
    organization,
    t,
    tenant,
    organizationLanguages,
    marketCommissionTypes,
  } = props;
  const { directSelling } = tenant?.settings || {};
  const { defaultLanguage, languages } = organization;
  const { commissionTypes: organizationCommissionTypes } = organization || {};
  const initialCommissionTypes = marketCommissionTypes.map(commissionType => {
    const exist = organizationCommissionTypes.find(
      c => c.type === commissionType,
    );
    if (exist) {
      return exist;
    }
    const translations = {};
    // eslint-disable-next-line no-return-assign
    languages.forEach(orgLanguage => (translations[orgLanguage] = ''));
    return {
      translations,
      type: commissionType,
    };
  });

  const [commissionTypes, setCommissionTypes] = useState(
    initialCommissionTypes || [],
  );
  useEffect(() => propagateChanges(commissionTypes), [commissionTypes]);

  const handleChanges = useCallback((field, language, value) => {
    const type = field.split('-')?.[0];
    setCommissionTypes(old =>
      old.map(cType => {
        if (cType?.type === type) {
          const updatedType = { ...cType };
          if (language?.toLowerCase() === defaultLanguage?.toLowerCase()) {
            updatedType.name = value;
          }
          updatedType.translations[language] = value;
          return updatedType;
        }
        return cType;
      }),
    );
  }, []);

  useImperativeHandle(ref, () => ({
    getValue: () => commissionTypes,
    setValue: newCommissionTypes => setCommissionTypes(newCommissionTypes),
  }));

  return (
    <>
      {directSelling ? (
        <TabCommissionTypes
          {...childProps}
          commissionTypes={commissionTypes}
          activeTypesOnOrganization={marketCommissionTypes}
          onChange={handleChanges}
          organizationLanguages={organizationLanguages}
          defaultLanguage={defaultLanguage}
        />
      ) : (
        <FeatureNotAvailable
          t={t}
          title={t('settings.configurations.commissionTypes')}
        />
      )}
    </>
  );
});

TabCommissionTypesWebHandler.propTypes = {
  t: PropTypes.func.isRequired,
  propagateChanges: PropTypes.func.isRequired,
  organization: PropTypes.shape(OrganizationProp.propType).isRequired,
  tenant: PropTypes.shape(TenantProp.propType).isRequired,
  organizationLanguages: PropTypes.arrayOf(
    PropTypes.shape(CountryProp.propType),
  ).isRequired,
  marketCommissionTypes: PropTypes.shape(PropTypes.string),
};

TabCommissionTypesWebHandler.defaultProps = {
  marketCommissionTypes: [],
};

window.TabCommissionTypes = class TabCommissionTypesRenderer {
  constructor(divId) {
    this.divId = divId;
    this.ref = null;
    this.listeners = {};
  }

  init(divId, props) {
    if (divId) {
      this.divId = divId;
    }

    const divEl = document.getElementById(divId);
    if (!divEl) {
      throw 'TabCommissionTypes container not found';
    }

    let finalProps = {};

    const propsData = divEl.getAttribute('data-props');
    if (propsData) {
      finalProps = JSON.parse(propsData);
    }

    const data = document.querySelector('script[data-react-props]');
    let globalProps = {};
    if (data) {
      globalProps = JSON.parse(data.getAttribute('data-react-props'));
    }

    if (props) {
      finalProps = { ...globalProps, ...finalProps, ...props };
    }

    ReactDOM.render(
      <TabCommissionTypesWebHandler
        ref={c => {
          this.ref = c;
        }}
        t={i18n.__}
        propagateChanges={changes => this.emit('change', changes)}
        {...finalProps}
      />,
      divEl,
    );
    return this;
  }

  val(value) {
    if (value) {
      this.ref.setValue(value);
      return undefined;
    }

    return this.ref.getValue();
  }

  on(type, callback) {
    if (!this.listeners[type]) {
      this.listeners[type] = [];
    }
    this.listeners[type].push(callback);
  }

  emit(type, ...args) {
    if (this.listeners[type]) {
      for (let i = 0; i < this.listeners[type].length; i += 1) {
        if (this.listeners[type][i]) {
          this.listeners[type][i](...args);
        }
      }
    }
  }
};
